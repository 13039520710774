import React, { useState, useEffect, MouseEvent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import logo from '../static/img/logo.svg';
import LangSwitcher from './LangSwitcher';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: '100%',
      right: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '84%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '60%',
      },
    },
    grow: {
      flexGrow: 1,
    },
    logo: {
      width: (logoWidth) => `${logoWidth}em`,
      paddingTop: (logoWidth) => (logoWidth > 9 ? '1em' : '0.2em'),
      transition: 'cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.5s',
      cursor: 'pointer',
    },
    menu: {
      textTransform: 'uppercase',
      fontSize: '14px',
    },
    devider: {
      background: theme.palette.common.white,
      height: '35px',
      marginTop: '16px',
    },
    deviderMobile: {
      background: theme.palette.common.white,
      height: '35px',
      marginTop: '16px',
      marginRight: '8px',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

const Navbar: React.FunctionComponent = () => {
  const logoDefaultWidth = 11;
  const [logoWidth, setLogoWidth] = useState<number>(logoDefaultWidth);
  const classes = useStyles(logoWidth);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { t } = useTranslation();
  const menuTitles = [
    { id: 'Offer', translation: t('Offer') },
    { id: 'Company', translation: t('Company') },
    { id: 'Contact', translation: t('Contact') },
  ];

  useEffect(() => {
    window.addEventListener('scroll', resizeHeaderOnScroll);
  }, []);

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    const shrinkOn = 300;
    if (distanceY > shrinkOn) {
      setLogoWidth(logoDefaultWidth - 3);
    } else {
      setLogoWidth(logoDefaultWidth);
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {menuTitles.map(({ id, translation }) => (
        <MenuItem key={id} className={classes.menu} aria-controls={id}>
          <Link to={id} smooth duration={500} offset={-100}>
            <Typography variant="body1" className={classes.menu}>
              {translation}
            </Typography>
          </Link>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link to="back-to-top-anchor" smooth duration={500} id="my-imge">
            <img
              src={logo}
              className={classes.logo}
              id="elektro-cz-logo"
              alt="elektro-cz-logo"
            />
          </Link>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop} role="menu">
            {menuTitles.map(({ id, translation }) => (
              <MenuItem key={id} className={classes.menu} aria-controls={id}>
                <Link to={id} smooth duration={500} offset={-100}>
                  {translation}
                </Link>
              </MenuItem>
            ))}
            <Divider orientation="vertical" flexItem className={classes.devider} />
            <LangSwitcher type="desktop" />
          </div>
          <div className={classes.sectionMobile} role="menu">
            <LangSwitcher type="mobile" />
            <Divider orientation="vertical" flexItem className={classes.deviderMobile} />
            <IconButton
              aria-label="open drawer"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
};

export default Navbar;
