import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import { Grid } from '@material-ui/core';

import backgroundImg from '../static/img/background.png';
import backgroundImgP from '../static/img/background.webp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    floatingText: {
      position: 'absolute',
      top: 'auto',
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(8),
      },
      [theme.breakpoints.up('xl')]: {
        paddingTop: theme.spacing(12),
      },
    },
    image: {
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(30),
        height: '100vh',
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(27),
        height: '75vh',
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(24),
        height: '70vh',
      },
      [theme.breakpoints.up('xl')]: {
        paddingTop: theme.spacing(34),
      },
    },
    header: {
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('sm')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.2rem',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '2.4rem',
      },
    },
  }),
);

const Header: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      id="back-to-top-anchor"
    >
      <picture>
        <source type="image/webp" srcSet={backgroundImgP} />
        <CardMedia
          component="img"
          image={backgroundImg}
          alt="elektrocz-background"
          width="1600"
          height="560"
          className={clsx(
            classes.image,
            'animate__animated',
            'animate__fadeIn',
            'animate_fast',
          )}
        />
      </picture>
      <Box className={classes.floatingText}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={6}>
          <Grid item sm={12}>
            <Typography
              variant="h4"
              color="textSecondary"
              className={clsx(
                classes.header,
                'animate__animated',
                'animate__zoomIn',
                'animate_fast',
              )}
            >
              {t('Header')}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Link to="Contact" smooth duration={500} offset={-100}>
              <Button
                variant="contained"
                color="secondary"
                className="animate__animated animate__pulse animate_fast"
              >
                {t('GetInTouch')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Header;
