/* eslint-disable react/prop-types */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DoneAll from '@material-ui/icons/DoneAll';

import Divider from '@material-ui/core/Divider';
import { Box } from '@material-ui/core';
import { MainContentCardProps } from './helpers/types';

const Offer: React.FunctionComponent<MainContentCardProps> = ({
  titleCls,
  deviderCls,
  contentCls,
}) => {
  const { t } = useTranslation();
  const offers: string[] = t('OfferList', { returnObjects: true });

  return (
    <div id="Offer">
      <Typography variant="h5" color="secondary" className={titleCls}>
        {t('Offer')}
      </Typography>
      <Divider className={deviderCls} />
      <Box className={contentCls}>
        {offers.map((offer) => (
          <List aria-label="main offer" key={offer}>
            <ListItem key={offer}>
              <ListItemIcon>
                <DoneAll />
              </ListItemIcon>
              <ListItemText primary={offer} />
            </ListItem>
          </List>
        ))}
      </Box>
    </div>
  );
};

export default Offer;
