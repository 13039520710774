import abb from '../../static/img/abb.png';
import abbP from '../../static/img/abb.webp';
import unisPower from '../../static/img/unisPower.png';
import unisPowerP from '../../static/img/unisPower.webp';

const customersLogos = [
  { name: 'abb-logo', path: abb, pathWebP: abbP },
  { name: 'unis-power-logo', path: unisPower, pathWebP: unisPowerP },
];

export default customersLogos;
