import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import { LangSwitcherProps } from './helpers/types';
import AVAILABLE_LANGUAGES from './helpers/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: { color: theme.palette.common.white, margin: `18px ${theme.spacing(2)}px` },
    icon: {
      fill: theme.palette.common.white,
    },
  }),
);

const LangSwitcher: React.FunctionComponent<LangSwitcherProps> = ({
  type,
}: LangSwitcherProps) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const changeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
    const language = event.target.value as string
    i18n.changeLanguage(language);
    window.history.replaceState(null, "", `/${language}/`)
  };

  return (
    <>
      <Select
        labelId="select-language-label"
        id={`select-language-${type}`}
        onChange={changeLanguage}
        value={i18n.language.split('-')[0]}
        disableUnderline
        className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {Object.entries(AVAILABLE_LANGUAGES).map(([key, content]) => (
          <MenuItem key={key} value={key}>{content}</MenuItem>
        ))}
      </Select>
    </>
  );
};

export default LangSwitcher;
