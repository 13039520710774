import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        Header: 'Production of industrial switchboards according to a project',
        Offer: 'Offer',
        Company: 'Company',
        Contact: 'Contact',
        Customers: 'Our customers',
        GetInTouch: 'Get in touch',
        Footer: '2021 Electro CZ. All rights reserved',
        AboutCompany:
          'The firm ELEKTRO CZ Ltd. was founded on 2.1.2006 as a successor of the business - association “Z+V Tvarůžek”, which was established in the year 1995. To the main industry belong the metering and regulation mostly on the energy units as power stations, heat stations, boiler-rooms, incinerators and others. At the present time the most of our production is bent on switch-boards.',
        OfferList: [
          'the production of low tension switch-boards',
          'the production, installation and repairs of electronic equipment',
          'the mediation of trade and services',
          'specialized retail',
          'technical counselling in the area of engineering, metallurgy and energetic',
          'assembly, reparation, revision and tests of electric equipment',
        ],
        Industry: 'Switchboards for industrial automation',
        Reports: 'Revision reports',
        Employees: 'Experienced workers',
        Office: 'Office',
        OfficeAddress: ['Komenského nám. 1226', 'Rosice, 665 01'],
        OfficePhones: [
          { name: 'Zdeněk Tvarůžek', phone: '+420 603 241 060' },
          { name: 'Lukáš Matějka (en)', phone: '+420 774 400 404' },
          { name: 'Tomáš Tvarůžek (invoicing)', phone: '+420 777 144 712' },
        ],
        Facility: 'Facility',
        FacilityAddress: ['Křívánky 12C', 'Brno - Bosonohy, 642 00'],
        FacilityPhones: [
          { name: '', phone: '+420 604 728 377' },
          { name: '', phone: '+420 792 305 504' },
        ],
        Carriers: 'Info for carriers',
        CarriersAddress: ['Křívánky 12C', 'Brno - Bosonohy, 642 00'],
        CarriersPhones: [
          { name: 'Zbyněk Jonáš (warehouse)', phone: '+420 776 744 639' },
          { name: '', phone: '+420 604 728 377' },
        ],
        Invoice: 'Billing information',
        InvoiceAddress: [
          'ELEKTRO CZ Ltd.',
          'Residence: V Cihelně 921, 665 01 Rosice',
          'IČ: 27670066',
          'DIČ: CZ27670066',
          'The company is registered at the Register Court in Brno, Section C, File 50538',
        ],
      },
    },
    de: {
      translations: {
        Header: 'Produktion von industriellen Schalttafeln nach Projekt',
        Offer: 'Bieten',
        Company: 'Firma',
        Contact: 'Kontakt',
        Customers: 'Unsere Kunden',
        GetInTouch: 'Melde dich jetzt',
        Footer: '2021 Electro CZ. Alle Rechte vorbehalten',
        AboutCompany:
          'Die Firma ELEKTRO CZ GmbH wurde gegründet am 2. Januar 2006 als Nachfolger des Unternehmervereines „Z+V Tvarůžek“, der seit dem Jahre 1995 tätig war. Unser Augenmerk richtet sich auf das Messen und die Regulierung von Stromkreisen, vorwiegend energetischer Einheiten wie Kraftwerke, Heizkraftwerke, Kesselhäuser, Verbrennungsanlagen und ähnlicher. Derzeit orientiert sich der Großteil der Produktion auf die Herstellung von Schaltanlagen.',
        OfferList: [
          'Herstellung von Niederspannungsanlagen',
          'Herstellung, Installation und Reparatur elektronischer Anlagen',
          'Vermittlung von Aufträgen und Dienstleistungen',
          'spezialisierter Kleinwarenhandel',
          'Technische Beratung im Maschinenbau-, Mettalurgie- und Energetik-Bereich',
          'Montage, Reparatur, Revision und Prüfung verschiedener elektrischen Anlagen',
        ],
        Industry: 'Schalttafeln für die industrielle Automatisierung',
        Reports: 'Revisionsberichte',
        Employees: 'Erfahrene Arbeiter',
        Office: 'Büro',
        OfficeAddress: ['Komenského nám. 1226', 'Rosice, 665 01'],
        OfficePhones: [
          { name: 'Zdeněk Tvarůžek', phone: '+420 603 241 060' },
          { name: 'Lukáš Matějka (en)', phone: '+420 774 400 404' },
          { name: 'Tomáš Tvarůžek (Fakturierung)', phone: '+420 777 144 712' },
        ],
        Facility: 'Einrichtung',
        FacilityAddress: ['Křívánky 12C', 'Brno - Bosonohy, 642 00'],
        FacilityPhones: [
          { name: '', phone: '+420 604 728 377' },
          { name: '', phone: '+420 792 305 504' },
        ],
        Carriers: 'Infos für Spediteure',
        CarriersAddress: ['Křívánky 12C', 'Brno - Bosonohy, 642 00'],
        CarriersPhones: [
          { name: 'Zbyněk Jonáš (Warenhaus)', phone: '+420 776 744 639' },
          { name: '', phone: '+420 604 728 377' },
        ],
        Invoice: 'Billing information',
        InvoiceAddress: [
          'ELEKTRO CZ GmbH',
          'Die Residenz: V Cihelně 921, 665 01 Rosice',
          'IČ: 27670066',
          'DIČ: CZ27670066',
          'Die Gesellschaft ist beim Registergericht in Brno, Abschnitt C, Akte 50538, eingetragen',
        ],
      },
    },
    cs: {
      translations: {
        Header: 'Výroba průmyslových rozvaděčů dle projektu',
        Offer: 'Nabídka',
        Company: 'Společnost',
        Contact: 'Kontakt',
        Customers: 'Naši zákazníci',
        GetInTouch: 'Kontaktujte nás',
        Footer: '2021 Electro CZ. Všechna práva vyhrazena',
        AboutCompany:
          'ELEKTRO CZ s. r. o. vznikla 2. ledna 2006 jako následník sdružení podnikatelů Z+V Tvarůžek, která působila od roku 1995. Hlavní náplní je oblast elektro, měření a regulace většinou na energetických celcích jako elektrárny, teplárny, kotelny, kompresorové stanice, spalovny a podobně. Nyní je většina produkce zaměřena na výrobu rozvaděčů.',
        OfferList: [
          'výroba rozvaděčů nízkého napětí',
          'výroba, instalace a opravy elektronických zařízení',
          'zprostředkování obchodu a služeb',
          'specializovaný maloobchod',
          'činnost technických poradců v oblasti strojírenství, hutnictví a energetiky',
          'montáž, opravy, revize a zkoušky vyhrazených elektrických zařízení',
        ],
        Industry: 'Rozvaděče pro průmyslovou automatizaci',
        Reports: 'Revizní zprávy',
        Employees: 'Zkušení pracovníci',
        Office: 'Kancelář',
        OfficeAddress: ['Komenského nám. 1226', 'Rosice, 665 01'],
        OfficePhones: [
          { name: 'Zdeněk Tvarůžek', phone: '+420 603 241 060' },
          { name: 'Lukáš Matějka (en)', phone: '+420 774 400 404' },
          { name: 'Tomáš Tvarůžek (fakturace)', phone: '+420 777 144 712' },
        ],
        Facility: 'Provozovna',
        FacilityAddress: ['Křívánky 12C', 'Brno - Bosonohy, 642 00'],
        FacilityPhones: [
          { name: '', phone: '+420 604 728 377' },
          { name: '', phone: '+420 792 305 504' },
        ],
        Carriers: 'Info pro dopravce',
        CarriersAddress: ['Křívánky 12C', 'Brno - Bosonohy, 642 00'],
        CarriersPhones: [
          { name: 'Zbyněk Jonáš (sklad)', phone: '+420 776 744 639' },
          { name: '', phone: '+420 604 728 377' },
        ],
        Invoice: 'Fakturační údaje',
        InvoiceAddress: [
          'ELEKTRO CZ s.r.o.',
          'Sídlo: V Cihelně 921, 665 01 Rosice',
          'IČ: 27670066',
          'DIČ: CZ27670066',
          'Společnost je vedená u rejstříkového soudu v Brně, oddíl C, vložka 50538',
        ],
      },
    },
  },
  fallbackLng: 'cs',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});

export default i18n;
