/* eslint-disable react/prop-types */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import ListItem from '@material-ui/core/ListItem';

import { MainContentCardProps } from './helpers/types';
import customersLogos from './helpers/customersLogos';

const Customers: React.FunctionComponent<MainContentCardProps> = ({
  titleCls,
  deviderCls,
  contentCls,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" color="secondary" className={titleCls}>
        {t('Customers')}
      </Typography>
      <Divider className={deviderCls} />

      <Grid container alignItems="center" spacing={5} className={contentCls}>
        {customersLogos.map(({ name, path, pathWebP }) => (
          <Grid item xs={12} sm={6} key={name}>
            <ListItem component="div">
              <picture>
                <source type="image/webp" srcSet={pathWebP} />
                <CardMedia component="img" image={path} id={name} alt={name} />
              </picture>
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Customers;
