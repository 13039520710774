/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { MainContentCardProps, HiglightsProps } from './helpers/types';
import {
  IndustryAvatar,
  ReportsAvatar,
  EmployeesAvatar,
} from './helpers/Icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      padding: theme.spacing(3),
      animationDelay: (delay) => `${delay}s`,
    },
    content: {
      paddingTop: theme.spacing(3),
      maxWidth: theme.spacing(16),
    },
  }),
);

const HighlightsItem: React.FunctionComponent<HiglightsProps> = ({
  icon,
  text,
  delay,
}) => {
  const classes = useStyles(delay);
  return (
    <Grid
      item
      container
      xs={12}
      sm={4}
      md={4}
      direction="column"
      className={clsx(classes.item, 'animate__animated', 'animate__zoomInDown')}
      alignItems="center"
    >
      <Grid item>{icon}</Grid>
      <Grid item className={classes.content}>
        <Typography variant="body1" align="center">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Highlights: React.FunctionComponent<MainContentCardProps> = ({ contentCls }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4} className={contentCls}>
      <HighlightsItem icon={<IndustryAvatar />} text={t('Industry')} delay={0} />
      <HighlightsItem icon={<ReportsAvatar />} text={t('Reports')} delay={0.3} />
      <HighlightsItem icon={<EmployeesAvatar />} text={t('Employees')} delay={0.8} />
    </Grid>
  );
};

export default Highlights;
