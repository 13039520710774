import React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      background: theme.palette.primary.main,
      width: '100%',
      padding: '1em',
    },
  }),
);

const Footer: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.footer}>
      <Typography variant="body2" align="center" color="textSecondary">
        {t('Footer')}
      </Typography>
    </Box>
  );
};

export default Footer;
