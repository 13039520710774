import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';
import MainContent from './components/MainContent';
import ScrollTop from './components/ScrollTop';
import AVAILABLE_LANGUAGES from './components/helpers/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      backgroundColor: '#eeeeee',
      [theme.breakpoints.up('lg')]: {
        padding: '0 8%',
      },
      [theme.breakpoints.up('xl')]: {
        padding: '0 20%',
      },
    },
    rootupper: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

const App: React.FunctionComponent = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  useEffect(() => {
    const languageInUrl = window.location.pathname.replaceAll('/', '')
    const currentLanguage = AVAILABLE_LANGUAGES[languageInUrl] ? languageInUrl: 'cs'
    i18n.changeLanguage(currentLanguage);
    window.history.replaceState(null, "", `/${currentLanguage}/`)
  }, [i18n])

  return (
    <div className={classes.rootBox}>
      <div className={classes.rootupper}>
        <Navbar />
        <Header />
      </div>
      <MainContent />
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default App;
