/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import { ReactComponent as industry } from '../../static/img/industry.svg';
import { ReactComponent as reports } from '../../static/img/reports.svg';
import { ReactComponent as employees } from '../../static/img/employees.svg';
import { ReactComponent as send } from '../../static/img/send.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.light,
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    icon: {
      fill: theme.palette.text.primary,
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  }),
);

const IndustryAvatar = () => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar}>
      <SvgIcon component={industry} className={classes.icon} viewBox="7 7 50 50" />
    </Avatar>
  );
};

const ReportsAvatar = () => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar}>
      <SvgIcon component={reports} className={classes.icon} viewBox="5.5 5.5 90 90" />
    </Avatar>
  );
};

const EmployeesAvatar = () => {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar}>
      <SvgIcon component={employees} className={classes.icon} viewBox="8 8 32 32" />
    </Avatar>
  );
};

const SendIcon = () => {
  return <SvgIcon component={send} viewBox="0 0 24 26" />;
};

export { IndustryAvatar, ReportsAvatar, EmployeesAvatar, SendIcon };
