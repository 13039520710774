/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import VizSensor from 'react-visibility-sensor';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { MainContentCardProps, ContactProps, AddressProps } from './helpers/types';
import { SendIcon } from './helpers/Icons';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontWeight: 700,
    },
  }),
);

const ContactCard: React.FunctionComponent<ContactProps> = ({
  title,
  address,
  phones,
  email,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Grid container spacing={1} justify="flex-start">
        <Grid item xs={12}>
          <Typography variant="body1" align="left" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {address && <AddressTile address={address} />}
          {email && <EmailTile />}
        </Grid>
        {phones &&
          phones.map((phonesObj, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <Typography variant="body2" align="left">
                  {phonesObj.phone}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" align="left">
                  {phonesObj.name}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    </Grid>
  );
};

const AddressTile: React.FunctionComponent<AddressProps> = ({ address }) => {
  return (
    <>
      {address.map((line, index) => (
        <Typography variant="body2" align="left" key={index}>
          {line}
        </Typography>
      ))}
    </>
  );
};

const EmailTile: React.FunctionComponent = () => {
  return (
    <VizSensor>
      {({ isVisible }) => (
        <Button
          variant="contained"
          color="secondary"
          endIcon={<SendIcon />}
          href="mailto:info@elektrocz.eu"
          className={clsx(isVisible && 'animate__animated animate__pulse')}
        >
          info@elektrocz.eu
        </Button>
      )}
    </VizSensor>
  );
};

const Contact: React.FunctionComponent<MainContentCardProps> = ({
  titleCls,
  deviderCls,
  contentCls,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" color="secondary" className={titleCls}>
        {t('Contact')}
      </Typography>
      <Divider className={deviderCls} />
      <Box className={contentCls}>
        <ListItem component="div" key="contact">
          <Grid container spacing={5} justify="flex-start">
            <ContactCard
              title={t('Office')}
              address={t('OfficeAddress', { returnObjects: true })}
              phones={t('OfficePhones', { returnObjects: true })}
            />
            <ContactCard
              title={t('Facility')}
              address={t('FacilityAddress', { returnObjects: true })}
              phones={t('FacilityPhones', { returnObjects: true })}
            />
            <ContactCard
              title={t('Carriers')}
              address={t('CarriersAddress', { returnObjects: true })}
              phones={t('CarriersPhones', { returnObjects: true })}
            />
            <ContactCard
              title={t('Invoice')}
              address={t('InvoiceAddress', { returnObjects: true })}
            />
            <ContactCard title="Email" email />
          </Grid>
        </ListItem>
      </Box>
    </>
  );
};

export default Contact;
