import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Offer from './Offer';
import AboutCompany from './AboutCompany';
import Customers from './Customers';
import Highlights from './Highlights';
import Contact from './Contact';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      height: 'auto',
      marginBottom: '1em',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      [theme.breakpoints.up('md')]: {
        width: '85%',
      },
    },
    gridTile: {
      [theme.breakpoints.down('sm')]: {
        margin: '1.5em',
      },
      [theme.breakpoints.up('md')]: {
        margin: '2em',
      },
      [theme.breakpoints.up('lg')]: {
        margin: '3em',
      },
    },
    gridTileContact: {
      [theme.breakpoints.down('sm')]: {
        margin: '0em 1.5em 3em 1.5em',
      },
      [theme.breakpoints.up('md')]: {
        margin: '0em 2em 3.5em 2em',
      },
      [theme.breakpoints.up('lg')]: {
        margin: '0em 3em 4em 3em',
      },
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      marginTop: '2em',
    },
    content: { marginTop: '1.5em'},
    contentList: { marginTop: '1em' },
    contentHighlights: { marginTop: '1em', [theme.breakpoints.down('sm')]: {
      marginTop: '3.5em',
    }, },
    divider: {
      width: '4em',
      marginTop: '0.4em',
      backgroundColor: '#00000052',
    },
  }),
);

const MainContent: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-around" bgcolor="white">
        <Box className={classes.grid}>
          <Box className={classes.gridTile}>
            <Highlights
              contentCls={classes.contentHighlights}
            />
            <Offer
              titleCls={classes.title}
              deviderCls={classes.divider}
              contentCls={classes.contentList}
            />
            <AboutCompany
              titleCls={classes.title}
              deviderCls={classes.divider}
              contentCls={classes.content}
            />
            <Customers
              titleCls={classes.title}
              deviderCls={classes.divider}
              contentCls={classes.content}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-around" bgcolor="#f5f7fa">
        <Box className={classes.grid}>
          <Box className={classes.gridTileContact} id="Contact">
            <Contact
              titleCls={classes.title}
              deviderCls={classes.divider}
              contentCls={classes.content}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainContent;
