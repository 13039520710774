/* eslint-disable react/prop-types */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { Box } from '@material-ui/core';
import { MainContentCardProps } from './helpers/types';

const AboutCompany: React.FunctionComponent<MainContentCardProps> = ({
  titleCls,
  deviderCls,
  contentCls,
}) => {
  const { t } = useTranslation();

  return (
    <div id="Company">
      <Typography variant="h5" color="secondary" className={titleCls}>
        {t('Company')}
      </Typography>
      <Divider className={deviderCls} />
      <Box className={contentCls}>
        <ListItem component="div" key="about-us">
          <Typography variant="body1" align="justify">
            {t('AboutCompany')}
          </Typography>
        </ListItem>
      </Box>
    </div>
  );
};

export default AboutCompany;
