/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { I18nextProvider } from 'react-i18next';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import i18n from './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './static/css/animate.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1a1c25',
      light: '#93959f',
    },
    secondary: {
      main: '#EC0000',
      light: '#fed766',
    },
    text: {
      primary: '#1a1c25',
      secondary: '#fff',
    },
  },
});

theme.shadows[4] =
  '0px 2px 4px -1px rgba(25,30,38,0.2),0px 4px 5px 0px rgba(25,30,38,0.14),0px 1px 10px 0px rgba(25,30,38,0.12)';

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
